import axios from "axios";
import router  from "../router";


let apiBaseUrl = window._CONFIG['domianURL'] || '/jeecg-boot'

// 创建 axios 实例
const service = axios.create({
  baseURL: apiBaseUrl, // api base_url
  timeout: 12000000 // 请求超时时间
})

//添加请求拦截器
service.interceptors.request.use(
  (config) => {
    
     const token = localStorage.getItem('token');
    // const token ='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkYXRhIjp7InBhc3N3b3JkIjoiZTVmODEwZTItYjcwOC00NDRmLWFlZGMtNzlmZGEyMTFiYzA4IiwiZXhwaXJlIjoiMjAyNC0xMS0xNCAxMDo1Nzo0OCIsInVzZXJuYW1lIjoidXNlcl8yMDI0MDcxNzExMDY0Nl82MmRlYTQifX0.dy2DE0Tqc-QXEMDR595_W9BXmBqC9LPziUQBdO1WKXAMipX0.3S0m3GhtuLouP2ZPwLNbhDxlGLgPaOaJ1Hlfou'
    config.headers['X-Access-Token'] = token;
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code==401) {
       const ua = navigator.userAgent;
        if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
         window.webkit.messageHandlers.LogAgain.postMessage("LogAgain");
        } else if (/Android/.test(ua)) {
         cwindow.handleMessage.LogAgain("LogAgain");
       }
    }
    return response;
  },
  error => {
    // 检查错误响应的状态码
    if (error.response && error.response.data) {
      if (error.response.data.code === 401) { 
         const ua = navigator.userAgent;
        if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
         window.webkit.messageHandlers.LogAgain.postMessage("LogAgain");
        } else if (/Android/.test(ua)) {
         cwindow.handleMessage.LogAgain("LogAgain");
       }
        return;
      }
      
    }
    // 如果你想在错误处理后继续抛出错误，以便可以在调用时使用.catch捕获
    return Promise.reject(error);
  }
);

export {
  service as request
}










